.nav-active {
  color: #f31260;
  font-weight: 700;
  font-size: medium;
}

.notActive {
  font-weight: 600;
  font-size: medium;
}

.notActive:hover {
  color: #f31260;
}
.navActive::after {
  content: "";
  height: 0.15rem;
  border-radius: 1rem;
  background-color: #f31260;
  transform: translateY(0.2rem);
  animation: drawLine 0.5s forwards;
}

@keyframes drawLine {
  from {
    width: 0%; /* Start with width 0 */
  }
  to {
    width: 100%; /* End with full width */
  }
}

.nav {
  font-family: "Inter";
}

.logo {
  justify-content: center !important;
}

.ribbon {
  background-color: #e9ecef;
  border: 3px solid black;
}

.slant-down {
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 50% 100%, 0 calc(100% - 30px));
}

.left-ribbon-border {
  position: absolute;
  height: 7px;
  width: 100%;
  left: -1rem;
  rotate: 25deg;
  background-color: black;
  top: 15.5rem;
}

.right-ribbon-border {
  position: absolute;
  height: 7px;
  width: 100%;
  right: -1rem;
  rotate: -25deg;
  background-color: black;
  top: 15.5rem;
}
