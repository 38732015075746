.cp-title {
    font-family: 'Merriweather' sans-serif;
    font-size: 20px;
    font-weight: 800;
}
.cp-errors{
    color: red;
    font-size:12px;
    font-family:'Merriweather';
    margin-left: 5px;
}

.cp-textarea {
    width: 100% !important;
    padding: 15px;
    font-size: 18px;
    border-radius: 5px;
    border : 1px solid rgb(133, 133, 133) !important;
}
.cp-textarea-error {
    width: 100% !important;
    padding: 15px;
    font-size: 18px;
    border-radius: 5px;
    border : 1px solid red !important;
}

.cp-textarea:hover {
    border-color: black !important;
}

/* .cp-textarea:focus-visible {
    border-color: blue !important;
} */

.cp-textarea:focus {
    border: 2px solid blue !important;
    border-radius: 5px;
    /* border-color: blue;  */
    outline: none; /* Remove default focus outline */
}

.cp-form-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 100px;
}

.cp-wrapper {
    padding: 100px 30px;
}
.cp-container {
    max-width: 80%;
    margin: 0 auto !important;
    width: 100%;
}

.cp-img-list {
    margin-top: 20px;
}

/* .cp-img-upload input::file-selector-button {
    font-weight: bold;
    color: dodgerblue;
    padding:10px;
    border: thin solid grey;
    border-radius: 3px;
} */
.cp-img-upload{
    min-width: 500px;
}
.cp-img-upload label{
  background-color: indigo;
  width: 100%;
  display: block;
  text-align: center;
  color: white;
  padding: 1rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  text-align: center;
}

.img-content-wrapper{
    position: relative;
}
.cp-icon{
    position: absolute;
    top: 0px;
    right: -17px;
    cursor: pointer;
    border-radius: 48px 47px;
    background-color: white;
}
@media(max-width: 1440px) {

    .cp-img-upload{
        min-width: 400px;
    }

}
@media(max-width: 1220px) {

    .cp-img-upload{
        min-width: 300px;
    }
    .img-content-wrapper img{
        height: 130px !important;
    }
}

@media(max-width: 1024px) {
    .cp-form-wrapper {
        display: block;
    }


    .cp-img-upload label {
        margin-top: 10px;
    }

}
@media(max-width: 424px) {
    .cp-img-upload{
        min-width: 200px;
    }
}