.successbg {
  background-color: #e5e5f7;
  opacity: 0.8;
  background-image: repeating-radial-gradient(circle at 0 0, transparent 0, #e5e5f7 10px),
    repeating-linear-gradient(#74dfa255, #74dfa2);
}

.failbg{
  background-color: #e5e5f7;
opacity: 0.8;
background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient( #FAA0BF55, #FAA0BF );
}
