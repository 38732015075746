.firstSection {
  background-image: linear-gradient(rgba(207, 198, 198, 0) 0%, rgba(216, 208, 208, 0)),
    url("https://images.unsplash.com/photo-1635719921169-288afcd9b128?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.secondSection {
  background-image: linear-gradient(rgba(238, 229, 229, 0) 0%, rgba(241, 230, 230, 0)),
    url("https://images.unsplash.com/photo-1617178199047-f987da4d11af?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.thirdSection {
  background-image: linear-gradient(rgba(235, 227, 227, 0) 0%, rgba(231, 222, 222, 0)),
    url("https://images.unsplash.com/photo-1550770472-745a80f68d07?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 1023.8px) {
  .attractive-small {
    width: 100%;
  }
}
